import { useEffect, useState } from 'react';
import React from "react";
import { Helmet } from 'react-helmet-async';

import posts from '../data/posts.json';

import './Post.scss';
import Header from '../layouts/Header';
import Section from '../layouts/Section';
import Gallery from '../components/Gallery';
import FramedArea from '../layouts/FramedArea';
import Map from '../components/Map';
import Chart from '../components/Chart';
import JournalEntry from '../layouts/JournalEntry';
import Image from '../components/Image';
import Video from '../components/Video';
import Related from '../layouts/Related';
import Footer from '../layouts/Footer';
import NumberedSection from '../layouts/NumberedSection';
import { useParams, useSearchParams } from 'react-router-dom';

function Post() {

    const { id } = useParams();

    const [searchParams] = useSearchParams();
    const actualLanguage = searchParams.get("lang");

    const [language, setLanguage] = useState({
        languageToChange: actualLanguage === "en" ? "HU" : "EN",
        actualLanguage: actualLanguage === null ? "HU" : actualLanguage.toUpperCase()

    });

    console.log(language.actualLanguage)

    const [colors, setColors] = useState({
        primary: posts[id].colors.primary,
        dark: posts[id].colors.dark,
        light: posts[id].colors.light
    });

    useEffect(() => {
        setColors({
            primary: posts[id].colors.primary,
            dark: posts[id].colors.dark,
            light: posts[id].colors.light
        });
    }, [id]);

    function handleLanguageChange(e) {
        setLanguage({
            languageToChange: language.actualLanguage,
            actualLanguage: e.target.textContent

        });
    };

    const KeysToComponentMap = {
        header: Header,
        section: Section,
        gallery: Gallery,
        framedArea: FramedArea,
        map: Map,
        chart: Chart,
        journalEntry: JournalEntry,
        image: Image,
        video: Video,
        numberedSection: NumberedSection
    };

    function renderer(config) {
        if (typeof KeysToComponentMap[config.component] !== "undefined") {
            return React.createElement(
                KeysToComponentMap[config.component],
                {
                    language: language.actualLanguage,
                    languageToChange: language.languageToChange,
                    primaryColor: colors.primary,
                    darkColor: colors.dark,
                    lightColor: colors.light,
                    handleLanguageChange: handleLanguageChange,
                    headerTitle: config.headerTitle,
                    headerSubTitle: config.headerSubTitle,
                    headerDetails: config.headerDetails,
                    headerBackground: config.headerBackground,
                    backgroundPositionX: config.backgroundPositionX,
                    backgroundPositionY: config.backgroundPositionY,
                    cardBackgroundPositionY: config.cardBackgroundPositionY,
                    headerReadingTime: config.headerReadingTime,
                    sectionTitle: config.sectionTitle,
                    sectionSubTitle: config.sectionSubTitle,
                    sectionText: config.sectionText,
                    id: config.id,
                    framedTitle: config.framedTitle,
                    framedText: config.framedText,
                    journalDate: config.journalDate,
                    journalText: config.journalText,
                    image: config.image,
                    images: config.images,
                    video: config.video,
                    sequenceTexts: config.sequenceTexts
                }
            );
        }
    }

    return (
        <div>
            <Helmet>
                <title>WWOOF Blog</title>
                <meta property="og:title" content={posts[id].meta.title} data-react-helmet="true" />
                <meta property="og:description" content={posts[id].meta.description} data-react-helmet="true" />
                <meta property="og:image" content={posts[id].meta.image} data-react-helmet="true" />
            </Helmet>
            <div className='app-container' style={{ backgroundColor: colors.light }}>
                {posts[id][language.actualLanguage].map(config => renderer(config))}
                <Related posts={posts} language={language.actualLanguage} />
                <Footer language={language.actualLanguage} />
            </div>
        </div>
    );
};

export default Post
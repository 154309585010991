import './Related.scss';
import Card from '../components/Card';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';

function Related({ posts, language }) {

    const currentPost = useParams();
    const [actualLanguage, setActualLanguage] = useState(language);

    useEffect(() => {
        setActualLanguage(language);
    })

    return (
        <section className='related'>
            <div className='related-title'>
                <div className='line'></div>
                <h2>{actualLanguage === "EN" ? "RELATED" : "KAPCSOLÓDÓ"}</h2>
                <div className='line'></div>
            </div>
            <div className='related-container'>
                {Object.keys(posts).map((postId) =>
                    postId !== currentPost.id ? <Card key={postId} postId={postId} post={posts[postId][language]} primaryColor={posts[postId].colors.primary} /> : undefined
                )}
            </div>
        </section>
    )
}

export default Related
import './Video.scss';

function Video({ video }) {
    return (
        <div className='container video-container'>
            <iframe className="video-iframe" src={'https://player.vimeo.com/video/' + video + '?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="length-of-the-fence"></iframe>
            <script src="https://player.vimeo.com/api/player.js"></script>
        </div >
    )
}

export default Video
import './NumberedSection.scss';
import { useState, useEffect } from 'react';

function NumberedSection({ sequenceTexts, language, lightColor, darkColor, primaryColor }) {

    const [actualLanguage, setActualLanguage] = useState(language);

    useEffect(() => {
        setActualLanguage(language);
    });

    function parseTextWithLinks(text) {
        const regex = /\[([^\]]+)\]\(([^)]+)\)/g;
        const parts = [];
        let lastIndex = 0;
        let match;

        while ((match = regex.exec(text)) !== null) {
            if (match.index > lastIndex) {
                parts.push({ type: 'text', content: text.slice(lastIndex, match.index) });
            }
            parts.push({ type: 'link', content: match[1], href: match[2] });
            lastIndex = regex.lastIndex;
        }

        if (lastIndex < text.length) {
            parts.push({ type: 'text', content: text.slice(lastIndex) });
        }

        return parts;
    };

    const numberedSections = sequenceTexts.map((text, i) =>
        <div className='numbered-section'>
            <div className='numbered-watermark' style={{ color: darkColor }}>
                <p>{i + 1}</p>
            </div>
            <p className='numbered-text' style={{ color: primaryColor }}>
                {sequenceTexts[i].split().map((text, id) => {
                    const parts = parseTextWithLinks(text);
                    return (
                        <p key={id}>
                            {parts.map((part, i) => {
                                if (part.type === 'text') {
                                    return part.content;
                                }
                                if (part.type === 'link') {
                                    return (
                                        <a key={i} href={part.href} target="_blank" rel="noopener noreferrer" style={{ color: primaryColor }} >
                                            {part.content}
                                        </a>
                                    );
                                }
                                return null;
                            })}
                        </p>
                    )
                })}
            </p>
        </div>
    )

    return (
        <section className="numbered-section-container" style={{ backgroundColor: lightColor }}>
            {numberedSections}
        </section>
    )
}

export default NumberedSection
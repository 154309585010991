import "./Gallery.scss";

function Gallery({ images, primaryColor }) {

    const colorFilterCss = `
        .gallery-image::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: ${primaryColor};
        opacity: .1;
        pointer-events: none;
    }
    `

    return (
        <div className="container gallery-container">
            <style>{colorFilterCss}</style>
            {images.map((url) =>
                <div key={url} className="gallery-image" style={{ backgroundImage: 'url(' + url + ')' }}></div>
            )}
        </div>
    )
}

export default Gallery
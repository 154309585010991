import './JournalEntry.scss';
import { useState, useEffect } from 'react';

function JournalEntry({ journalText, journalDate, language, lightColor, darkColor, primaryColor }) {

    const [actualLanguage, setActualLanguage] = useState(language);

    useEffect(() => {
        setActualLanguage(language);
    })

    return (
        <section className="container journal-entry" style={{backgroundColor: lightColor}}>
            <div className='journal-watermark' style={{ color: darkColor }}>
                <p>{actualLanguage === "EN" ? "EXCERPT FROM MY DIARY" : "RÉSZLET A NAPLÓMBÓL"}</p>
            </div>
            <p className='journal-date' style={{ color: primaryColor }}>{journalDate}</p>
            <p className='journal-text' style={{ color: primaryColor }}>{journalText}</p>
        </section>
    )
}

export default JournalEntry
import './Card.scss';
import clock from '../assets/clock-outline-black.svg'
import { Link } from 'react-router-dom';

function Card({ postId, post, primaryColor }) {
    return (
        <div className="card-container">
            <Link
                to={"/woof/" + postId}
                onClick={() => {
                    window.scroll(0, 0)
                }}>
                <div className='card-img-container'>
                    <div className="card-img" style={{ backgroundImage: 'url(' + post[0].headerBackground + ')', backgroundPositionY: post[0].cardBackgroundPositionY }}></div>
                    <div className='card-img-grey-filter'></div>
                    <div className='card-img-color-filter' style={{ backgroundColor: primaryColor }}></div>
                </div>
                <div className="card-txt">
                    <h3>{post[0].headerTitle}</h3>
                    <h4>{post[0].headerSubTitle}</h4>
                    <div className="card-details">
                        <h5>{post[0].headerDetails}</h5>
                        <div className='reading-time'>
                            <img src={clock} alt="Olvasási idő" className='clock-img' />
                            <h5>{post[0].headerReadingTime}</h5>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default Card
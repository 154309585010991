import './FramedArea.scss';

function FramedArea({ framedTitle, framedText, lightColor, primaryColor }) {

    const frameCss = `
@media (min-width: 1000px) {
    .box {
        position: relative;
        --b: 0.04em;
        --c: ${primaryColor + "77"};
        --w: calc(40% - 4.5em);
        border: var(--b) solid transparent;
        padding: var(--b);
        
        background:
            linear-gradient(var(--c), var(--c)) top left / var(--w) var(--b) no-repeat,
            linear-gradient(var(--c), var(--c)) top left / var(--b) var(--w) no-repeat,
            linear-gradient(var(--c), var(--c)) bottom right / var(--w) var(--b) no-repeat,
            linear-gradient(var(--c), var(--c)) bottom right / var(--b) var(--w) no-repeat;
    }
}
`
    const framedTextArr = framedText.split();

    function parseTextWithLinks(text) {
        const regex = /\[([^\]]+)\]\(([^)]+)\)/g;
        const parts = [];
        let lastIndex = 0;
        let match;

        while ((match = regex.exec(text)) !== null) {
            if (match.index > lastIndex) {
                parts.push({ type: 'text', content: text.slice(lastIndex, match.index) });
            }
            parts.push({ type: 'link', content: match[1], href: match[2] });
            lastIndex = regex.lastIndex;
        }

        if (lastIndex < text.length) {
            parts.push({ type: 'text', content: text.slice(lastIndex) });
        }

        return parts;
    };
    return (
        <section className="container framed-area" style={{ backgroundColor: lightColor }}>
            <style>{frameCss}</style>
            <div className='box'>
                <div className='margin'>
                    <p className='framed-title' style={{ color: primaryColor }}>{framedTitle}</p>
                    {framedTextArr.map((text, id) => {
                        const parts = parseTextWithLinks(text);
                        return (
                            <p key={id} className='framed-text' style={{ color: primaryColor }}>
                                {parts.map((part, i) => {
                                    if (part.type === 'text') {
                                        return part.content;
                                    }
                                    if (part.type === 'link') {
                                        return (
                                            <a key={i} href={part.href} target="_blank" rel="noopener noreferrer" style={{ color: primaryColor }} >
                                                {part.content}
                                            </a>
                                        );
                                    }
                                    return null;
                                })}
                            </p>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}

export default FramedArea
import "./Section.scss";
import SectionHeader from "./SectionHeader";

function Section({ sectionTitle, sectionSubTitle, sectionText, lightColor, darkColor, primaryColor }) {

    const sectionTextArr = sectionText.split();

    function parseTextWithLinks(text) {
        const regex = /\[([^\]]+)\]\(([^)]+)\)/g;
        const parts = [];
        let lastIndex = 0;
        let match;

        while ((match = regex.exec(text)) !== null) {
            if (match.index > lastIndex) {
                parts.push({ type: 'text', content: text.slice(lastIndex, match.index) });
            }
            parts.push({ type: 'link', content: match[1], href: match[2] });
            lastIndex = regex.lastIndex;
        }

        if (lastIndex < text.length) {
            parts.push({ type: 'text', content: text.slice(lastIndex) });
        }

        return parts;
    };

    return (
        <section className="section-normal" style={{ backgroundColor: lightColor, color: darkColor }}>
            {sectionTitle === undefined ? "" : <SectionHeader title={sectionTitle} subTitle={sectionSubTitle} />}
            {sectionTextArr.map((text, id) => {
                const parts = parseTextWithLinks(text);
                return (
                    <p key={id}>
                        {parts.map((part, i) => {
                            if (part.type === 'text') {
                                return part.content;
                            }
                            if (part.type === 'link') {
                                return (
                                    <a key={i} href={part.href} target="_blank" rel="noopener noreferrer" style={{ color: primaryColor }} >
                                        {part.content}
                                    </a>
                                );
                            }
                            return null;
                        })}
                    </p>
                )
            })}
        </section>
    );
}

export default Section
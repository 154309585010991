import './Image.scss';

function Image({ image }) {
    return (
        <div className='container image-container'>
            <div className="image" style={{ backgroundImage: 'url(' + image + ')' }}></div>
        </div >
    )
}

export default Image